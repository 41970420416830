/** 
 *   我的
 */
import { get, post, put, del } from '@/untils/js/axios.js'

// 获取浏览记录  足迹
export const getRecord = params => get('/user/foot-list', params)

// 删除浏览记录  足迹
export const delRecord = id => get(`/user/del-foot/${id}`)

// 删除所有浏览记录  足迹
export const delAllRecord = params => get('/user/del-foot', params)

// 获取收藏列表
export const getFavorite = params => get('/goods/collect-list', params)

// 删除&收藏商品
export const favoriteGoods = (id,params) => post(`/goods/handle-collect/${id}`, params)

// 为您推荐
export const getAccountRem = params => get('/goods/recommend/my', params)

// 用户信息
export const getAccountInfo = params => get('/account/info', params)

// 用户信息 修改
export const editAccountInfo = params => put('/account/editinfo', params)

// 用户 绑定查询
export const userBind = params => get('/account/bind-query', params)

// 用户 绑定email
export const bindEmail = params => post('/account/bindemail', params)

// 用户绑定手机号
export const bindPhone = params => post('/account/bindphone', params) 

// 用户 修改密码
export const repass = params => post('/account/repass', params)

// 用户 关注 收藏 足迹 优惠券
export const my_total = params => get('/user/statistics', params)




/** 
 *   地址
 */

// 城市列表
export const city        = params => get('/city/getcity', params)


// 个人地址 列表
export const address     = params => get('/useraddrs', params)

// 个人地址 添加
export const address_add = params => post('/useraddrs', params)

// 个人地址 修改
export const address_put = (id,params) => put(`/useraddrs/${id}`, params)

// 个人地址 删除
export const address_del = params => del(`/useraddrs/${params}`)

//// 获取抽奖码
export const user_luck_draw = params => get('user_luck_draw', params)

// 试听领取
export const try_hear = params => get('try_hear', params)

// 试听领取状态
export const try_hear_use = params => get('try_hear_use', params)
/** 
 *   商家入驻
 */

// 经营类目
export const category    = params => get('/shops/category', params)

// 入驻申请
export const shop_apply  = params => post('/shops/apply', params)

// 入驻申请
export const shop_status = params => get('/shops/applystatus', params)


/** 
 *   优惠券
 */
//  优惠券列表与搜索
export const coupon     = params => get('/coupon_list', params)
//  优惠券 兑换
export const coupon_get = params => get('/get_coupon', params)


/** 
 *   问题反馈
 */
//  问题反馈
export const feedback  = params => post('/feedback/add-post', params)


/** 
 *   用户钱包
 */
//  钱包
export const wallet    = params => get('/user/wallet', params)
//  钱包明细
export const wallet_record = params => get('/user/wallet-detailed', params)
//  钱包 提现
export const withdrawal = params => post('/user/wallet-withdrawal', params)

//  银行卡列表
export const card_list = params => get('/user/card-list', params)

//  银行卡 添加
export const card_add = params => post('/user/add-card', params)
//  银行卡 删除
export const card_del = params => post(`/user/del-card/${params}`, )


/** 
 *   合伙人钱包
 */

// 合伙人信息
export const agent_info    = params => get('/agent/info', params)

//  钱包
export const agent    = params => get('/agent/wallet', params)
//  钱包明细
export const agent_record = params => get('/agent/wallet-detailed', params)
//  钱包 提现
export const agent_withdrawal = params => post('/agent/wallet-withdrawal', params)

// 获取店铺关注列表
export const getFollowed = params => get('/shops/follow-list', params)
// 取消店铺关注
export const cancleFollow = (id, params) => post(`/shops/follow-cancel/${id}`, params)

// 重新请求
export const reApply = params => post('/shops/setviewstatus', params)

// 是否是游客
export const isGuest = params => get('/users/is-guest', params)

// 分享图
export const luck_draw_hb = params => post(`https://api.ggdnn.com/luck_draw_hb`,params)

// 判断是否领取
export const a_get_coupon = params => get(`a_get_coupon`,params)

// 领取优惠券
export const get_coupon = params => get("get_coupon",params)

// 抽奖码数量
export const total_luck_code = params => get("total_luck_code",params)


// 双11游戏奖品入口
export const mooby_luck_goods = params => get(`mooby_luck_goods`, params);